<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>医患管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path:'/patientManage' }">注册患者</el-breadcrumb-item>
            <el-breadcrumb-item>报名详情管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="text-align: left;width: 100%;">
            <!-- <el-button size="small" @click="edit">编辑</el-button> style="margin-left:20px" -->
            <el-button size="small" @click="change">修改状态</el-button>
        </div>
        <h4 style="text-align: left;">患者信息</h4>
        <el-divider></el-divider>
        <div class="userMain">
            <div style="width: 50%;float: left;">
                <p>姓名：{{user.name}}</p>
                <p>年龄：{{user.age}}</p>
                <p>性别：{{user.sex}}</p>
            </div>
            <div style="width: 50%;float: right;">
                <p>当前疾病：{{user.ill}}</p>
                <p>当前治疗状态：
                    <span v-if="user.cure_status == 0">匹配中</span>
                        <span v-if="user.cure_status == 1">治疗中</span>
                        <span v-if="user.cure_status == 2">已治愈</span>
                </p>
            </div>
        </div>
        <el-divider></el-divider>
        <h4 style="text-align: left;">报名信息</h4>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column label="报名疾病" prop="ill"></el-table-column>
            <el-table-column label="报名疾病描述" prop="ill_desc"></el-table-column>
            <!-- <el-table-column>
                <template slot-scope="scope">
                    <div class="opt doc">
                        <a @click="edit(scope.row)">编辑</a>
                        <a @click="change(scope.row)">修改状态</a>
                    </div>
                </template>
            </el-table-column> -->
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
        <!-- 编辑 -->
        <!-- <el-dialog class="tcform" title="编辑疾病" :visible.sync="bianji">
            <el-form label-width="170px">
                <el-form-item label="选择疾病" prop="jb">
                    <el-select style="float: left; width: 40%;" class="zzks" v-model="jb" placeholder="请选择疾病">
                        <el-option v-for="item in jboptions" :key="item.value" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="bianji = false;jb = ''">取 消</el-button>
                <el-button type="primary" @click="subbj">确 定</el-button>
            </div>
        </el-dialog> -->
        <!-- 修改状态 -->
        <el-dialog class="tcform" title="修改状态" :visible.sync="zhuangtai">
            <el-form label-width="170px">
                <el-form-item label="状态列表" prop="zt">
                    <el-select style="float: left; width: 40%;" class="zzks" v-model="zt" placeholder="请选择状态">
                        <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="zhuangtai = false;zt = ''">取 消</el-button>
                <el-button type="primary" @click="subzt">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                id: '',
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                list: [],
                user: {},
                bianji: false,
                zhuangtai: false,
                jb: '',
                jbname:'',
                zt: '',
                options: [
                    { id: 0, name: '匹配中' },
                    { id: 1, name: '治疗中' },
                    { id: 2, name: '已治愈' },
                ],
                jboptions:[],
            }
        },
        mounted() {
            this.id = this.$route.query.id;
            this.getguills(1);
            this.getusermessage();
            // this.getill();
        },
        methods: {
            getill(){
                this.axios.get("/gu/get_gu_ill_lib")
                    .then((res) => {
                        if (res.data.code == 0) {
                            console.log(res.data.result)
                            this.jboptions = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            getusermessage() {
                this.axios.get("/gu/get_gu_cust_by_id?id=" + this.id)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.user = res.data.result;
                            this.zt = res.data.result.cure_status;
                            this.jb = res.data.result.ill;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            getguills(page) {
                let desc = [], ill = [];
                this.axios.get("/gu/get_gu_ills?page=" + page + "&size=" + this.PageSize + "&cust_id=" + this.id)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.totalCount = res.data.count;
                            ill = res.data.result;
                            for (let i = 0; i < ill.length; i++) {
                                ill[i].ill_desc = '';
                            }
                            desc = res.data.desc;
                            for (let m = 0; m < desc.length; m++) {
                                for (let n = 0; n < ill.length; n++) {
                                    if (desc[m].ill_id == ill[n].id) {
                                        ill[n].ill_desc += desc[m].ill_desc + '；';
                                    }
                                }
                            }
                            this.list = ill;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getguills(currentPage);
            },
            // edit() {
            //     this.bianji = true;
            // },
            change() {
                this.zhuangtai = true;
            },
            // subbj() {
            //     for(let a=0;a<this.jboptions.length;a++){
            //         if(this.jb == this.jboptions[a].id){
            //             this.jbname = this.jboptions[a].name
            //         }
            //     }
            //     let form = {
            //         id: this.id,
            //         ill: this.jbname,
            //         ill_lib_id:this.jb
            //     }
            //     console.log(form);
            //     this.axios.post("/gu/update_gu_cust_ill", this.qs.stringify(form))
            //         .then((res) => {
            //             if (res.data.code == 0) {
            //                 this.$message({
            //                     message: '编辑成功',
            //                     type: 'success'
            //                 });
            //                 this.reload();
            //             } else {
            //                 console.log(res)
            //             }
            //         })
            //         .catch((err) => {
            //             console.log(err);
            //         });
            // },
            subzt() {
                let form = {
                    id: this.id,
                    cure_status: this.zt
                }
                console.log(form)
                this.axios.post("/gu/update_gu_cust_cure_status", this.qs.stringify(form))
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                            this.reload();
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .userMain {
        text-align: left;
        width: 100%;
        overflow: hidden;
    }
</style>